.nsw-search-box{
  @include font-stack('body');
  background: $light10;
  padding: rem(30px) rem(16px);

  margin-left: -15px;
  margin-right: -15px;

  @include breakpoint('sm') {
    padding: rem(40px);
    margin-left: 0;
    margin-right: 0;
  }

  &__form{
    position: relative; 
  }

  &__input {
    @include font-stack;
    @include nsw-spacing(padding, none md);
    @include font-size('md');
    background: $white;
    border: 1px solid $light40;
    color: $dark80;
    border-radius: $border-radius;
    width: 100%;
    appearance: none;
    height: rem(50px);
    padding: 0 3rem 0 1rem;

    &:focus { 
      @include nsw-focus;
    } 

    &::-ms-clear {
      display: none;
    }
  }

  &__btn {
    background: none;
    appearance: none;
    border: 0;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: rem(10px);
    cursor: pointer;

    .nsw-icon {
      fill: $light40;
    }

    &:hover,
    &:focus {
      .nsw-icon {
        fill: $info-blue;
      }
    }

    &:focus {
      @include nsw-focus;
    }
  }
}
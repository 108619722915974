.feature-card{

    @include breakpoint(sm){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: rem(500);
        margin-top: rem(16);
        margin-left: rem(-22);
    }

    &__title{
        margin: 0;
        padding-top: rem(40);

        @include breakpoint(sm) {
          padding-bottom: 0;
        }
    }

    &__left,
    &__right{
        .nsw-card{
            margin-left: initial;
            margin-right: initial;
            max-width: initial;
        }
    }

    &__left{
        width: 100%;

        @include breakpoint(sm) {
            width: 50%;
            margin-left: rem(22);
        }
    }

    &__right{
        @include breakpoint(xs){
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            margin-left: rem(-16);

            .nsw-card{
                height: auto;
                width: 50%;
                margin-left: rem(16);
            }
        }

        @include breakpoint(sm) {
            flex-direction: column;
            -ms-flex-direction: column;
            width: 50%;
            margin-left: rem(22);

            .nsw-card{
                margin-left: 0;
            }

            .nsw-card:last-of-type{
                margin-top: rem(22);
            }
        }
    }

    

    .nsw-card{
        
        @include breakpoint(sm){
            max-width: initial;
            margin-top: 0;
            border-radius: 0;
            width: 100%;
        }

        &__link{
            font-size: rem(20);
        }

        &__copy{
            font-weight: 400;
        }
    }

    .nsw-card__content{
        padding: rem(16);

        @include breakpoint(sm){
            position: absolute;
            left: rem(20);
            bottom: rem(20);
            margin-right: rem(20);
            max-width: rem(280);
            background-color: $overlay-white;
            height: initial;
            z-index: 1;
        }
    }

    .nsw-card__image-area{
        @include breakpoint(sm){
            line-height: 0;;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
.card-video{

    margin-top: rem(16);

        &_video {
        width: 100%;
        flex-basis: 55%;
        position: relative;

        &-thumbnail{
            position: absolute;
            left: 0;
            top: 0;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 4;
            background-size: cover;
            width: 100%;
            height: 100%;
        }

        &-play{
            z-index: 5;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            border: 0;
            cursor: pointer;

            svg{
                height: rem(70);
                width: rem(70);
            }
        }
    }

    &_card {
        flex-basis: 45%;
    }

    @include breakpoint(sm) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0 rem(52);
    }

    .nsw-card{
        margin: 0;
        max-width: initial;
        border-radius: 0;

        &__link{
            font-size: rem(28);
            line-height: rem(34);
            color: $nsw-primary-blue;

            &.link__no-hover:hover {
                text-decoration: none;
            }
        }

        &__content{
            padding: rem(16);

            @include breakpoint(sm){
                padding: rem(32);
            }
        }

        &__copy{
            margin-bottom: rem(24);
        }
    }

    .video-wrapper{
        margin-bottom: 0;

        iframe{
            background-color: $black;
        }
    }

}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
ol,
li,
em,
small,
link,
abbr,
acronym,
blockquote {
  @include font-size(sm);
  font-family: $font-stack;
  color: inherit;
  line-height: inherit;
}

h1,
.h1 {
  @include font-size(xxxl);
}

h2,
.h2 {
  @include font-size(xxl);
}

h3,
.h3 {
  @include font-size(xl);
}

h4,
.h4 {
  @include font-size(lg);
}

h5,
.h5 {
  @include font-size(md);
}

link,
abbr,
abbr[title],
acronym {
  text-decoration: none;
}

blockquote {
  @include font-size(lg);
  color: $dark80;
  position: relative;
  padding-left: rem(22px);
  padding-bottom: 1rem;
  margin: rem(45px) auto;
  max-width: rem(630px);

  small {
    @include font-size(sm);
    display: block;
    padding-left: 1.5em;
    margin-top: 1em;
    position: relative;

    &::before {
      height: rem(1px);
      width: 1em;
      content: '';
      position: absolute;
      top: calc(50% - 0.5px);
      left: 0;
      transform: translateY(-50%);
      background: $dark80;
    }
  }
}

@mixin nsw-link {
  display: inline-flex;
  align-items: center;

  &::after {
    background-size: cover;
    margin-left: 0.5em;
    width: 1em;
    height: 1em;
    display: inline-block;
  }
}

a {
  color: $nsw-primary-blue;
  text-decoration: underline;

  .external {
    @include nsw-link;

    &::after {
      content: '';
      background-image: url('/assets/images/open_in_new.png');
    }
  }

  .pdf {
    @include nsw-link;

    &::after {
      content: '';
      background-image: url('/assets/images/pdf.png');
    }
  }
}

.page-content {
  color: $text-color;
  line-height: 1.25;
}

.primary-blue {
  color: $nsw-primary-blue;
}
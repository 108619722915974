.nsw-navigation{
  @include font-stack();
  width: 100%;
  position: absolute;
  left: 0;
  top: rem(39px);
  background: $white;
  transition: map-get($transitions, base);
  transform: translateX(-100%);
  visibility: hidden;
  outline: none;

  &.is-opening,
  &.is-open{
    transform: translateX(0);
    visibility: visible;
  }

  &.is-closing{
    transform: translateX(-100%);
  }

  @include breakpoint('sm'){
    @include font-stack('heading');
    position: relative;
    transform: none;
    background-color: $light10;
    top: auto;
    left: auto;
    transition: none;
    visibility: visible;
    border-top: solid 1px $light40;
  }

  &__close{
    width: rem(40px);
    height: rem(40px);
    background: none;
    border: 0;
    padding: 0;
    text-align: center;
    border-radius: rem($border-radius);

    &:hover{
      @include nsw-hover;
    }

    &:focus{
      @include nsw-focus;
    }

    .icon{
      height: rem(20px);
      width: rem(20px);
    }

    @include breakpoint('sm'){
      display: none;
    }
  }

  &__link-text{
    flex: 1 1 auto;
  }

  &__subbtn{
    width: rem(52px);
    height: rem(52px);
    background: none;
    border: 0;
    padding: 0;
    text-align: center;
    border-left: solid 1px $light20;

    &:hover{
      @include nsw-hover;
    }

    &:focus{
      @include nsw-focus;
    }

    .nsw-icon{
      width: rem(16px);
      height: rem(16px);
    }

    @include breakpoint('sm'){
      display: none;
    }
  }

}

.nsw-navigation__list,
.nsw-subnavigation__list{
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  @include breakpoint('sm'){
    @include container(0);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
  }
}

.nsw-subnavigation__list{
  @include breakpoint('sm'){
    @include nsw-spacing(margin-top, xl);
    display: flex;
    justify-content: space-between;
  }
}

.nsw-navigation__list-item,
.nsw-subnavigation__list-item{
  border-bottom: solid 1px $light20;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;

  // &:last-child{
  //   .nsw-navigation__link{
  //     color: $info-blue;
  //   }
  // } 

  @include breakpoint('sm'){
    border-bottom: 0;

    // &:last-child{
    //   margin-left: auto;
    // }
  }

  &--optional{
    margin-left: auto;

    .nsw-navigation__link{
      color: $info-blue;
    }
  }
}

.nsw-subnavigation__list-item{
  
  @include breakpoint('sm'){
    width: 32%;
    border-top: solid 1px $light40;

    &:nth-last-child(-n+3){
      border-bottom: solid 1px $light40;
    }
  }
}

.nsw-navigation__link,
.nsw-subnavigation__link{
  flex: 1 1 auto;
  padding: 16px;
  color: $dark80;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;

  @include breakpoint('sm'){
    position: relative;
    z-index: 2;
  }

  &:hover{
    @include nsw-hover;
  }

  &:focus{
    @include nsw-focus;
  }

  &.is-open{
    background-color: $white;
  }
}

.nsw-navigation__link-icon{
  position: relative;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  height: rem(12px);
  width: rem(12px);
  margin-left: rem(10px);
  display: none;

  @include breakpoint('sm'){
    display: block;
  }

  .is-open &{
    transform: translateY(-50%) rotate(270deg);
  }
}

.nsw-subnavigation{
  position: absolute;
  top: 0;
  background-color: $white;
  width: 100%;
  z-index: 1;
  transition: map-get($transitions, base);
  transform: translateX(-100%);
  visibility: hidden;
  z-index: 1;
  left: 0;

  &.is-open{
    transform: translateX(0);
    visibility: visible;
  }

  @include breakpoint('sm'){
    @include container;
    @include nsw-spacing(padding-top, lg);
    @include nsw-spacing(padding-bottom, lg);
    top: 100%;
    background: $white;
    box-shadow: map-get($shadows, menu);
    transition: none;
    transform: none;

    &.is-open{
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include breakpoint('md'){
    @include nsw-spacing(padding-top, xxl);
    @include nsw-spacing(padding-bottom, xxl);
  }
}

.nsw-navigation__header,
.nsw-subnavigation__header{
  @include font-stack('heading');
  @include font-size('md');
  @include container;
  border-bottom: solid 2px $nsw-primary-blue;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  padding-top: rem(10px);
  padding-bottom: rem(10px);
  position: relative;

  h2{
    margin: 0;
    flex: 1 1 auto;
  }
}

.nsw-navigation__header{
  @include breakpoint('sm'){
    display: none;
  }
}

.nsw-subnavigation__header{
  justify-content: space-between;

  @include breakpoint('sm'){
    @include font-stack();
    @include font-size('sm');
    border-bottom: 0;
    display: block;
    padding: 0;
  }
}

.nsw-subnavigation__back-btn{
  @include font-stack('heading');
  @include font-size('md');
  background: none;
  border: 0;
  border-radius: rem($border-radius);
  padding: rem(4px) 0;
  line-height: 1;

  &:hover{
    @include nsw-hover;
  }

  &:focus{
    @include nsw-focus;
  }

  .nsw-icon{
    width: rem(16px);
    height: rem(16px);
    margin-right: rem(6px);
  }

  @include breakpoint('sm'){
    display: none;
  }
}

.nsw-subnavigation__title{
  @include font-stack('heading');
  @include font-size('lg');
  display: none;

  @include breakpoint('sm'){
    display: inline-block;
  }
}

.nsw-subnavigation__title-link{
  @include font-size('lg');
  color: $nsw-primary-blue;
  text-decoration: none;
  display: inline-flex;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;

  &:hover{
    @include nsw-hover;
  }

  &:focus{
    @include nsw-focus;
  }

  .nsw-icon{
    @include nsw-spacing(margin-left, sm);
    margin-top: rem(4px);
    width: rem(24px);
    height: rem(24px);
  }
}

.nsw-subnavigation__description{
  @include nsw-spacing(margin-top, sm);
  display: none;

  @include breakpoint('sm'){
    display: block;
  }
}

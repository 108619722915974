$shadow: rgba($black, 0.2);

.search-autocomplete {

  &__items {
    position: absolute;
    margin-top: 0;
    background: $white;
    border-bottom-left-radius: rem(4px);
    border-bottom-right-radius: rem(4px);
    font-size: rem(14);
    color: $dark80;
    width: 100%;
    box-shadow: 0 4px 12px 0 $shadow;
    z-index: 1;

    @include breakpoint('md') {
      margin-top: rem(4px);
    }
  }

  &__item {
    @include font-stack('body');
    padding: rem(12px);
    border-bottom: 1px solid $light20;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;

      &.autocomplete-active,
      &:focus,
      &:hover {
        border-bottom-left-radius: rem(4px);
        border-bottom-right-radius: rem(4px);
      }
    }

    &:first-child {
      &.autocomplete-active,
      &:focus,
      &:hover {
        border-top-left-radius: rem(4px);
        border-top-right-radius: rem(4px);
      }
    }

    &.autocomplete-active,
    &:focus,
    &:hover {
      color: $nsw-primary-blue;
      background: $light10;
    }
  }
}
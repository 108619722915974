.nsw-search-tags {
  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: rem(10px) 0 0 0;
    @include breakpoint('sm') {
      margin-top: rem(10px); 
    }
  }

  &__btn {
    @include nsw-spacing(padding, xs sm );
    display: flex;
    align-items: center;
    margin-top: rem(10px); 
    margin-bottom: 0;
    margin-right: rem(10px);
    padding: rem(12.5px) rem(20px);
    font-weight: bold;
    color: $white;
    cursor: pointer;

    &.is-selected {
      border-color: $dark60;
      background-color: transparent;
      font-size: rem(14);
      color: $nsw-primary-blue;
    } 

    &-clear {
      margin-top: rem(10px); 
      margin-bottom: 0;
    }

    &:hover {
      background-color: lighten($dark60, 5.88);
      color: $white;

      .nsw-search-tags__icon-close{
        fill: $white;
      }
    }
    
  }

  &__icon-close {
    width: rem(13px);
    height: rem(13px);
    margin-left: rem(13px);
    fill: $nsw-primary-blue;
  }

}
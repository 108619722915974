.dpie-news{
    .nsw-grid>.nsw-col{
        padding: 0 rem(16);
        margin-bottom: rem(32);
    }

    .search-grid__results {
        @include breakpoint(sm) {
        flex-basis: 75%;
        max-width: 75%;
        }
    }

    .card-grid__cards{
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        align-items: stretch;

        .nsw-card{
            height: auto;
            width: 100%;
            margin: rem(8) 0;
            max-width: none;
            position: relative;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            overflow: hidden;
            border: 0;

            &:nth-child(odd){
                @include breakpoint(sm) {
                  margin: rem(8) rem(16) rem(8) 0;
                }
                @include breakpoint(md){
                    margin: rem(8) 0;
                }
            }

            &:not(:nth-child(3)){
                @include breakpoint(md){
                    margin: rem(8) rem(16) rem(8) 0;
                }
            }

            .nsw-card__image-height{
                height: rem(208);
            }

            .nsw-card__content{
                -webkit-box-flex: 1;
                flex: 1 1 auto;
                font-size: rem(16);
                padding: rem(16) rem(16) rem(64);
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;
                -webkit-box-ordinal-group: 3;
                order: 2;

                .nsw-card__icon{
                    position: absolute;
                    bottom: rem(16);
                    left: rem(16);
                }
            }
        }
    }

    .nsw-card__image-area{
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .nsw-card__image {
        height: 100%;               
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .card-grid--three .nsw-card {
        @include breakpoint(sm) {
            width: calc(50% - 8px);
        }

        @include breakpoint(md){
            width: calc(33.3333% - 16px);
        }
    }

    .nsw-card__title{
        font-size: rem(18);
        font-weight: 600;
        line-height: rem(22.5);
        margin: 0 0 rem(16) 0;

        a{
            text-decoration: none;

            &::before {
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            }
        }
    }

    .news-pagination{
        display: flex;
        justify-content: space-between;
        padding: rem(32) 0;

        &__pages{
            padding-top: rem(6);
        }

        ul{
            display: flex;
            justify-content: space-between;
            list-style-type: none;
            padding: 0;
            margin: 0;
        }   

        li{
            margin: 0 rem(16);
        }

        a{
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        &__control{
            padding: 0 rem(8);
        }


        &__prev-button{
            svg{
                transform: rotate(180deg);
            }
        }
    }
}
.au-side {

  &-wrapper.nsw-side-nav {
    margin-bottom: rem(40);
  }

  &-tiles {
    @include font-stack('body');
    margin: 0 rem(-12);
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      svg {
        width: rem(10);
        height: rem(16);
        text-decoration: none;
        position: relative;
        top: rem(3);
      }
    }

    &__title {
      font-size: rem(16);
      font-weight: 600;
      line-height: 1.44;
      margin: 0;
    }

    &__item {
      padding: rem(20) rem(12);
      width: 100%;
      box-sizing: border-box;
      position: relative;
    }

    &__link {
      padding-top: rem(24);
    }

    &__image {
      img {
        width: 100%;
        display: block;
        border: 0;
        height: auto;
        max-width: 100%;
      }
    }
  }
}

.au-display-md {
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.63;
  margin-top: rem(24);
}
.nsw-direction-link {
  @include font-stack('heading');
  @include nsw-spacing(margin-right, md);
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: rem(4px) rem(8px);
  min-width: rem(24px);
  position: relative;
  color: $dark80;

  &:hover{
    @include nsw-hover;
  }

  &:focus{
    @include nsw-focus;
  }

  &__icon-back {
    transform: rotate(-180deg);
  }

  &__icon-top {
    transform: rotate(-90deg);
  }

  &__icon-bottom {
    transform: rotate(90deg);
  }

  &__icon-next {
    transform: rotate(0deg);
  }

  .nsw-icon {
    width: rem(16px);
    height: rem(16px);
    vertical-align: middle;
  }
}

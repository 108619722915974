.nsw-news-listing {  
    
  &__item {
    margin-bottom: rem(10px);
    @include breakpoint('sm') {
      display: flex;
      margin-bottom: rem(30px);
    }
  }

  &__link {
    text-decoration: none;

    &:hover,
    &:focus {
      .nsw-news-listing__title {
        text-decoration: underline;
      }
    }

    &:focus {
      .nsw-news-listing__title {
        @include nsw-focus;
      }
    }
  }

  &__thumbnail {
    font-size: 0;
    border-top: rem(5px) solid $nsw-primary-highlight;

    img {
      width: 100%;
    }

    @include breakpoint('sm') {
      width: rem(400px);
    }
  }

  &__content {
    width: 100%;
  }

  &__header,
  &__summary {
    padding: rem(16px);

    @include breakpoint('sm') {
      padding: rem(27px);
    }
  }

  &__header {
    text-decoration: none;
    background: $light10;
  }

  &__title {
    @include font-stack('heading');
    @include font-size('md');
    color: $dark80;
    margin: 0;
  }

  &__publish-date {
    @include font-stack('body');
    font-size: rem(14px);
    color: $dark80;
    display: block;
    border-top: rem(1px) solid $light20;
    padding-top: rem(18px);
    margin-top: rem(20px);
  }

  &__summary {
    @include font-stack('body');
    @include font-size('sm');
    line-height: rem(20px);
    color: $dark70;

    p:first-of-type {
      margin-top: 0;
    }

    .nsw-tag {
      pointer-events: none;
    }

  }


  &__pagination {
    ul.nsw-pagination {
      justify-content: center;
    }
    
  }

}

.nsw-subscription{
    border: rem(2) solid $light10;
    font-family: $font-stack;
    color: $dark80;
    max-width: rem(300);
    margin: rem(60) auto;
    // margin: 0 auto;
    // margin-top: rem(60);
    
    @include breakpoint(sm) {
        margin-left: 0;
        margin-right: 0;
    }

    &_contents{
        padding: rem(24);
    }

    &_title{
        font-size: rem(20);
        line-height: rem(25);
    }

    &_copy{
        font-size: rem(14);
        line-height: rem(17.5);
    }

    &_privacy{
        color: $nsw-primary-blue;
        font-weight: 700;
        padding-top: rem(13);
    }

    &_confirm{
        padding: rem(25) 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p{
            font-size: rem(14);
            padding-left: rem(16);
        }
    }

    form{
        font-size: rem(16);

        input[type="text"],
        input[type="email"]{
            height: rem(48);
            width: 85%;
            font-size: rem(16);
            display: block;
        }

        input[type=checkbox] {
          transform: scale(2.5);
          margin-left: rem(10);
        }

        label{
            display: block;
            margin: rem(8) 0;
        }
    }


}
// sass-lint:disable no-color-hex no-color-literals

.nsw-footer {
  color: $white;

  &-links {
    margin: 0;

    &__item {
      margin-bottom: rem(8px);

      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }

    &__container {
      margin-bottom: rem(46px);
      
      @include breakpoint(sm) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(56px);
      }
    }

    &__link {
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }

    &__social {
      margin: 0 rem(12px);

      .nsw-socials__icon {
        display: block;
        fill: $white;
        height: rem(24px);
        transition: fill 0.3s ease-in-out;
        width: rem(24px);
      }

      &:hover {
        .nsw-socials__icon {
          &--facebook {
            fill: #3b5998;
          }

          &--twitter {
            fill: #00acee;
          }

          &--linkedin {
            fill: #0e76a8;
          }

          &--email {
            fill: $nsw-primary-highlight;
          }
        }
      }

      &:first-of-type {
        margin-left: 0;
      }

      @include breakpoint(sm) {
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &-social {
    display: flex;
    flex-direction: column;
    
    @include breakpoint(sm) {
      align-items: center;
      flex-direction: row;
    }

    &__list {
      @include font-stack('heading');
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 0 rem(8px) 0;
      padding: 0;
      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }

    &__title {
      font-weight: bold;
      font-size: rem(14px);
      margin: rem(26px) 0 1rem;
      @include breakpoint(sm) {
        margin: 0 rem(21px) 0 0;
      }
    }
  }

  &__copyright {
    font-size: rem(14px);
  }
}

p.nsw-footer__copyright {
  margin: 0;
}

.section-links__heading,
.section-links__heading a {
  font-size: 1.25rem;
}

.section-links__heading {
  color: $text-color;
}

form {
  font-family: Montserrat;
}

.sq-form {

  &-question {
    padding-bottom: rem(30px);
    color: $dark80;
    font-size: rem(16px);
    line-height: 1.25;

    legend,
    label,
    &-title {
      display: block;
      padding: 0 0 rem(10px) 0;
      color: $dark80;
      margin: 0;
    }

    &-helper-text,
    &-character-count,
    &-note {
      font-size: rem(14px);
    }

    &-helper-text,
    &-note {
      padding-bottom: rem(8px);
    }

    &-character-count {
      padding-top: rem(8px);
    }

    &-answer {
      caption {
        font-size: rem(14px);
        padding: rem(8px) 0;
      }
    }

    input[type="text"],
    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="tel"],
    textarea,
    select,
    .sq-form-field {
      font-family: Montserrat;
      font-size: rem(16px);
      color: $dark80;
      line-height: 1.25;
      padding: rem(14px) rem(12px);
      background-color: $white;
      border-radius: rem(4px);
      border: solid rem(1px) $dark70;
      max-width: 100%;
      width: 100%;
      overflow: hidden;
    }

    &-error {

      .sq-form-field,
      input[type="text"],
      input[type="date"],
      input[type="email"],
      input[type="tel"],
      input[type="number"] {
        border: solid rem(2px) $error-red;
      }

      &.sq-form-question-option-list,
      &.sq-form-question-tickbox-list {
        .sq-form-question-answer {
          border-radius: rem(9px);
          border: solid rem(3px) $error-red;
          padding: rem(8px);
        }
      }
    }

    &-date {
      .sq-form-question-answer {
        position: relative;

        input[type="date"] {
          height: auto;
          padding: 0 0 0 rem(12px);

          &::-webkit-inner-spin-button {
            display: none;
          }

          &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            opacity: 1;
            z-index: 1;
            color: transparent;
            background-image: url('../../assets/images/calendar.svg');
            background-repeat: no-repeat;
            background-size: rem(35px);
            background-position: center;
            background-color: $nsw-primary-blue;
            padding: rem(10px);
            width: rem(30px);
            height: rem(28px);
            margin: 0 0 0 rem(10px);
          }

          &:focus {
            outline: auto rem(2px) -webkit-focus-ring-color;
          }
        }
      }
    }

    &-datetime{

      select{
        background-image: url('../assets/images/down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%,0 0;
        background-size: 1em auto,100%;
        appearance: none;
      }
    }

    &-select,
    &-country {
      .sq-form-question-answer {
        position: relative;

        &::after {
          // content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="16" viewBox="0 0 17 16"><defs><path id="prefix__a" d="M0 0H16V16H0z"/></defs><g fill="none" fill-rule="evenodd"><mask id="prefix__b" fill="#fff"><use xlink:href="#prefix__a"/></mask><path fill="#002664" d="M8 3l8 8-2 2-6-6-6 6-2-2 8-8z" mask="url(#prefix__b)" transform="matrix(1 0 0 -1 0 16)"/></g></svg>');
          content: url('../assets/images/down.svg');
          display: block;
          position: absolute;
          top: rem(18px);
          right: rem(14px);
        }

        select {
          height: auto;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          z-index: 1;
          cursor: pointer;

          &::-ms-expand {
            display: none;
          }

          &:focus {
            outline: auto rem(2px) -webkit-focus-ring-color;
          }
        }
      }
    }

    &-option-list,
    &-tickbox-list,
    &-tickbox-table,
    &-datetime {
      fieldset {
        border: 0;
        margin: 0;
        padding: 0;
      }

      legend {
        padding-bottom: rem(24px);
      }

      &.sq-form-question-error {
        legend {
          padding-bottom: rem(10px);
        }
      }

      .sq-form-error {
        margin-top: rem(14px);
      }

      .sq-form-question-answer {
        position: relative;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          text-indent: 0;

          li {
            margin: 0;
            padding: 0;
            text-indent: 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:not(:last-child) {
              margin-bottom: rem(12px);
            }

            input[type="radio"],
            input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;

              &+label {
                display: block;
                position: relative;
                padding: rem(6px) 0 rem(6px) rem(42px);
                cursor: pointer;

                &::before,
                &::after {
                  display: block;
                  content: '';
                  position: absolute;
                  transform: translateY(-50%);
                }

                &::before {
                  width: rem(32px);
                  height: rem(32px);
                  left: 0;
                  top: 50%;
                  border: solid rem(2px) $dark70;
                }
              }

              &:focus {
                &+label {
                  &::before {
                    outline: auto rem(2px) -webkit-focus-ring-color;
                  }
                }
              }

              &:checked,
              &:disabled {
                &+label {
                  &::after {
                    width: rem(22px);
                    height: rem(22px);
                    left: rem(5px);
                    top: 50%;
                  }
                }
              }

              &:checked {
                &+label {
                  &::after {
                    background-color: $nsw-primary-blue;
                  }
                }
              }

              &:disabled {
                &+label {
                  &::after {
                    background-color: $light40;
                  }
                }
              }
            }

            input[type="radio"] {
              &+label {

                &::before,
                &::after {
                  border-radius: 50%;
                }
              }
            }

            input[type="checkbox"] {
              &+label {

                &::before,
                &::after {
                  border-radius: rem(4px);
                }
              }

              &.error {
                &+label {
                  &::after {
                    border-radius: rem(9px);
                    border: solid rem(3px) $error-red;
                    width: rem(42px);
                    height: rem(42px);
                    left: rem(-5px);
                    top: 50%;
                  }
                }

                &:checked {
                  &+label {
                    &::after {
                      width: rem(22px);
                      height: rem(22px);
                      left: rem(5px);
                      top: 50%;
                      background-color: $nsw-primary-blue;
                      padding: 0;
                      border-color: transparent;
                    }
                  }
                }
              }

              &:checked {
                &+label {
                  &::after {
                    // content: '✓';
                    // content: '\2713';
                    // content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFF" fill-rule="evenodd" d="M14.681 2.133L5.247 11.735 1.319 7.737 0 9.08 4.588 13.749 5.247 14.391 5.907 13.749 16 3.476z"/></svg>');
                    content: url('../assets/images/tick-form.svg');
                    padding: rem(3px);
                  }
                }
              }

              &:checked,
              &:disabled {
                &+label {
                  &::after {
                    border-radius: rem(1px);
                  }
                }
              }
            }
          }
        }
      }
    }

    &-tickbox-table {
      table {
        border-spacing: rem(8px);
        border-collapse: separate;

        td {
          padding: 10px;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;

        &+label {
          display: block;
          position: relative;
          padding: rem(6px) 0 rem(6px) rem(42px);
          cursor: pointer;

          &::before,
          &::after {
            display: block;
            content: '';
            position: absolute;
            transform: translateY(-50%);
          }

          &::before {
            width: rem(32px);
            height: rem(32px);
            left: 0;
            top: 50%;
            border: solid rem(2px) $dark70;
          }
        }

        &:focus {
          &+label {
            &::before {
              outline: auto rem(2px) -webkit-focus-ring-color;
            }
          }
        }

        &:checked,
        &:disabled {
          &+label {
            &::after {
              width: rem(22px);
              height: rem(22px);
              left: rem(5px);
              top: 50%;
            }
          }
        }

        &:checked {
          &+label {
            &::after {
              background-color: $nsw-primary-blue;
            }
          }
        }

        &:disabled {
          &+label {
            &::after {
              background-color: $light40;
            }
          }
        }
      }

      input[type="radio"] {
        &+label {

          &::before,
          &::after {
            border-radius: 50%;
          }
        }
      }

      input[type="checkbox"] {
        &+label {

          &::before,
          &::after {
            border-radius: rem(4px);
          }
        }

        &.error {
          &+label {
            &::after {
              border-radius: rem(9px);
              border: solid rem(3px) $error-red;
              width: rem(42px);
              height: rem(42px);
              left: rem(-5px);
              top: 50%;
            }
          }

          &:checked {
            &+label {
              &::after {
                width: rem(22px);
                height: rem(22px);
                left: rem(5px);
                top: 50%;
                background-color: $nsw-primary-blue;
                padding: 0;
                border-color: transparent;
              }
            }
          }
        }

        &:checked {
          &+label {
            &::after {
              // content: '✓';
              // content: '\2713';
              // content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFF" fill-rule="evenodd" d="M14.681 2.133L5.247 11.735 1.319 7.737 0 9.08 4.588 13.749 5.247 14.391 5.907 13.749 16 3.476z"/></svg>');
              content: url('../assets/images/tick-form.svg');
              padding: rem(3px);
            }
          }
        }

        &:checked,
        &:disabled {
          &+label {
            &::after {
              border-radius: rem(1px);
            }
          }
        }
      }
    }
  }

  &-error {
    padding: rem(14px) 0 0 0;
    font-size: rem(13px);
    background: $error-message;
    font-weight: 600;
    line-height: 1.23;
    padding: rem(8px) rem(16px) rem(8px) rem(32px);
    margin-top: rem(8px);
    position: relative;

    &::before {
      // content: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="#F9F9F9" d="M11.919 10.8L10.8 11.919 8 9.119 5.2 11.919 4.081 10.8 6.881 8 4.081 5.2 5.2 4.081 8 6.881 10.8 4.081 11.919 5.2 9.119 8z" transform="translate(-4 -4)"/></svg>');
      content: url('../assets/images/cross.svg');
      display: block;
      position: absolute;
      border-radius: 50%;
      background-color: $error-red;
      width: rem(16px);
      height: rem(16px);
      top: rem(8px);
      text-align: center;
      left: 0;
      margin: 0 rem(8px);
      color: $white;
    }
  }

  &-required-field {
    text-decoration: none;
    color: $error-red;

    &[title] {
      border: 0;
      text-decoration: none;
    }

    &-note {
      padding-bottom: rem(16px);
    }
  }

  &-captcha {
    padding-bottom: rem(16px);
  }

  &-captcha {
    font-family: Montserrat;
    padding-bottom: rem(30);

    img,
    label,
    input {
      margin-bottom: rem(8);
      display: inline-block;
    }
  }
}

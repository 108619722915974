.nsw-news-detail {

  .nsw-breadcrumb {
    display: none;
    @include breakpoint('sm') {
      display: block;
    }
  }

  &__wrapper {
    padding-right: 0;
    @include breakpoint('sm') {
      padding-right: rem(50);
    }
  }

  &__title {
    @include font-stack('heading');
    font-size: rem(26px);
    margin-top: rem(30px);  
    @include breakpoint('sm') {
      font-size: rem(32px);
      margin-top: 0;
    }
  }

  &__publish-date {
    @include font-stack('body');
    font-size: rem(14px);
    color: $dark80;
    display: block;
    border-bottom: rem(1px) solid $light20;
    padding-bottom: rem(18px);
    margin-bottom: rem(20px);
    margin-top: rem(25px);
    @include breakpoint('sm') {
      margin-top: rem(34px);
    }
  }

  &__tags {
    .nsw-tag {
      margin-top: 0;
      pointer-events: none;
    }
  }

  &__content {
    @include font-stack('body');
    margin-top: rem(25px);
    margin-bottom: rem(25px);
    @include breakpoint('sm') {
      margin-bottom: rem(50px);
    }
  }

  &__sidebar {
    margin-bottom: (50px);

    &-title {
      @include font-stack('heading');
      font-size: rem(28px);
      margin-top: 0;
      margin-bottom: 0;
      @include breakpoint('sm') { 
        margin-bottom: (24px);
      }
    }

    .nsw-card {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: rem(25px);
      max-width: 100%;
    }
  }

}
@mixin grid-columns(){
  .nsw-col {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;

    @each $breakpoint, $columns in $grid-col-sizes {
      @include breakpoint($breakpoint){
        @each $modifier, $size in $columns {
          &--#{$modifier} {
            flex: 0 0 $size;
            max-width: $size;
          }
        }
      }
    }
  }
}

@mixin container($offset: $container-offset){
  max-width: rem($container-max-width);
  padding-left: rem($offset);
  padding-right: rem($offset);
  margin-left: auto;
  margin-right: auto;
}

@mixin container-flush {
  padding-left: 0;
  padding-right: 0;
}

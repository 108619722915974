.nsw-img {
  min-height: 100%;
  min-width: 100%;

  &__wrapper {
    margin-bottom: 1em;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 75%;
    }

    @include breakpoint(md) {
      max-width: 50%;
    }

    &::before {
      background-color: $nsw-primary-highlight;
      content: '';
      height: rem(6px);
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    &--left {
      float: left;
      margin-right: rem(32px);

      ~ ul {
        li {
          list-style-position: outside;
        }
      }
    }

    &--right {
      float: right;
      margin-left: rem(32px);

      ~ ul {
        padding-top: 1px;
      }
    }
  }
}

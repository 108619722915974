.nsw-card--dpie-large{
    .nsw-card{
        max-width: rem(800);
    }
}

.card_dpie--image-right.nsw-card--dpie{

    @include breakpoint(sm){
        .nsw-card{
            flex-direction: row-reverse;
        }
    }
}
.nsw-tabs {
  &__list {
    flex-direction: column;
    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__link {
    height: 100%;  
  }
}

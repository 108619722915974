// sass-lint:disable no-color-literals

.nsw-img {
  &__wrapper--caption {
    box-shadow: 0 rem(4px) rem(12px) 0 rgba($black, 0.15);
    max-width: none;
    margin: 0 0 1em 0;
    width: 100%;

    figcaption {
      color: $caption-color;
      font-family: $font-stack;
      line-height: 1.5;
      padding: rem(24px) rem(32px);
      position: relative;

      &::before {
        content: '';
        height: rem(6px);
        width: 100%;
        position: absolute;
        top: rem(-6px);
        left: 0;
        background-color: $nsw-primary-highlight;
      }
    }

    &::before {
      content: none;
    }

    .nsw-img {
      width: 100%;
      height: auto;
    }
  }
}
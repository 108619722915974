// sass-lint:disable space-after-colon
.nsw-search-filters {

  &__title {
    @include font-stack('body');
    appearance: none;
    background: $dark60;
    width: 100%;
    color: $white;
    padding: rem(20px);
    border: 0;
    font-size: rem(14);
    text-align: left;
    font-weight: bold;
  }

  &__accordion-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @include breakpoint('md') { 
      pointer-events: auto;
    }

    &.active {
      .nsw-search-filters__accordion-icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__accordion-icon {
    fill: $white;
    transform: rotate(90deg);
    height: 1rem;
    width: 1rem;
    pointer-events: none;
  }

  &__categories {
    display: none;

    &.active {
      display: block;
    }
  }

  &__category {

    border-bottom: 1px solid $light20;

    &-title>span{
      font-size: rem(14);
    }

    &.active {
      .nsw-search-filters__items {
        display: block;
      }

      .nsw-search-filters__category-title {
        background: $light10;
        font-size: rem(14);
      }

      .nsw-search-filters__category-title-icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__category-title {
    @include font-stack('body');
    appearance: none;
    padding: rem(20px);
    width: 100%;
    border: 0;
    font-size: 1rem;
    color: $dark80;
    background: $white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @include breakpoint('md') {
      // background: $light10;
      pointer-events: auto;
    }

    &-icon {
      transform: rotate(90deg);
      width: 1rem;
      height: 1rem;
      fill: $nsw-primary-blue;
      pointer-events: none;
    }
  }

  &__items {
    list-style: none;
    margin: 0;
    padding: rem(25) rem(20);
    display: none;
  }

  &__item {
    margin-bottom: rem(8px);

  a {
      @include font-stack('body');
      font-size: rem(14);
      color: $dark70;
      text-decoration: none;
      position: relative;
      display: flex;
      padding: rem(8px) 0 0 rem(30px);

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.5625rem;
        width: 1rem;
        height: 1rem;
        border: 0.0625rem solid $dark70;
        border-radius: rem(2px);
      }

      &:hover {
        color: $nsw-primary-blue;
        text-decoration: underline;
      }

      &.active {
        color: $nsw-primary-blue;

        &::after {
          content: '';
          // sass-lint:disable-next-line
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAFMN540AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkFFMTY2Njk5RjkxMTFFOUI4RTJBN0Y2OTBFNkNEMzAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkFFMTY2NkE5RjkxMTFFOUI4RTJBN0Y2OTBFNkNEMzAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyQUUxNjY2NzlGOTExMUU5QjhFMkE3RjY5MEU2Q0QzMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyQUUxNjY2ODlGOTExMUU5QjhFMkE3RjY5MEU2Q0QzMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pg2m8FwAAAH9SURBVHjaYmDABgwMDP5D6WgMQQZkGZAgQAAx4AToWnBwgAAggHAKgsRAljHiUBhz4cKFpSAGE5okSAKkaQm6cdE4XKoCEEAgQhKHpDXI+CdAxl8sph2Bcf7CFIAkYI5kRFIN080EdRzC1UAQB+X/gwkwIdmxBKYDZgojugSKFUDGExxe+QsQQIxQfz4D2QXUzcxAAMBsAuKn6FYx4TIESRM8nhixOQfZEGyaYGoZ8TjvL1JQomjCqRndJiBehMs7jPj8hM87sHiyhicSHM7DZQhAAMGTHwMJAJaMGaGJQJoYW9FslgIR5VDxJbhyDQ5/P2d+8eLFZQkJiTtAgSAQBrFBYoTiHyQGJggZgCv1wRm4DMCXbAklkn/40juGAJoLGPHlNiYcAbsIWQ2uWGAikIJi8EUjM77oIBQLzISiA58BzMSUIrgMYCSkEU802oBLe/SynJABsBIVIMAY4dUFA8MBIAZltThicheJWTgaGv3PgdgBaP4dWEFyCC3K/1HDAUgWopttx0iEIpIdQIxZjORoosRCYqoaog0hx7GMlAQbUplBcugwUhhvDOSmB2ZiLYaWBHeBzAAsDiY5ETJSmEoZyM0FjJSmUnJzASOlKZpcPYzUysOkmsFI7VKLWAfAmtinoc0vqpXTeBzwFIhNURqBpDYcSXSANXJ/CQBcWI2Q3dTpvgAAAABJRU5ErkJggg==);          
          background-repeat: no-repeat;
          background-size: 0.625rem;
          background-position: center center;
          position: absolute;
          left: 0.1875rem;
          top: 0.75rem;
          width: 0.625rem;
          height: 0.625rem;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__reset{
    color: $white;
    text-decoration: none;
  }
}

.dpie-news{
	.nsw-search-filters__category-title,
	.nsw-search-filters__item{
		font-size: rem(14);
	}
}

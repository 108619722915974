// sass-lint:disable no-important

.sr-only {
  @include sr-only;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  @include sr-only-focus;
}

.nsw-inner-banner {
  background: $nsw-primary-blue;
  color: $white;
  font-family: $font-stack;
  // margin-bottom: rem(50px);
  position: relative;
  width: 100%;

  &--no-image {
    grid-template-rows: 1fr;
  }

  &--has-image {
    background: $white;
  }

  @include breakpoint(sm) {
    display: flex;
    // margin-bottom: 0;
  }

  .nsw-container {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
  }

  &__breadcrumb {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: rem(14px);
    font-weight: bold;
    padding-top: rem(16px);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include breakpoint(sm) {
      @include sr-only;
    }

    .nsw-icon {
      fill: $white;
      height: rem(14px);
      padding-left: 1rem;
      transform: rotate(180deg);
      width: 2rem;
    }
  }

  &__title {
    background: $nsw-primary-blue;
    font-weight: 600;
    line-height: 1.25;
    max-width: rem(400px);
    padding: rem(37px) rem(16px) rem(37px) 0;
    position: relative;
    z-index: 2;

    &--breadcrumb{
      padding: rem(37) rem(16) rem(70) 0;
      
      @include breakpoint(sm){
            padding: rem(37px) rem(16px) rem(37px) 0; 
      }
    }

    &--full-width{
      background-color: transparent;
    }

    @include breakpoint(sm) {
      max-width: rem(500px);
      padding: rem(74px) 0;
    }

    @include breakpoint(md) {
      max-width: rem(650px);
    }
  }

  &__bg-img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;

    &__container {
      align-items: center;
      background-position: center;
      background-size: cover;
      display: none;
      grid-column: 1;
      grid-row: 2;
      justify-content: center;
      overflow: hidden;
      position: relative;

      @include breakpoint(sm) {
        display: flex;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 40%;
      }
    }
  }

  &__full-width{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

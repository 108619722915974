$footer--border-color: $light20;
$footer-upper--bg: $white;
$footer-lower--bg: $dark80;
$footer--text-color: $white;
$footer-hr--bg: $dark60;

.nsw-footer{
  @include font-stack;
  border: solid 1px $footer--border-color;

  p{
    margin: 0 0 rem(16px) 0;
  }

  hr{
    height: 1px;
    border: 0;
    color: $footer-hr--bg;
    background-color: $footer-hr--bg;
    margin: rem(16px) 0;
  }

  &__upper{
    background-color: $footer-upper--bg;
    padding: rem(28px) 0 0;
  }

  &__lower{
    background-color: $footer-lower--bg;
    color: $footer--text-color;
    padding: rem(28px) 0;
    font-size: rem(12px);

    a{
      color: $footer--text-color;
    }
  }
}

.nsw-footer-links{
  @include font-stack('heading');
  margin: 0 0 rem(8px) 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__item{
    flex: 1 0 50%;
    max-width: 50%;
    margin-bottom: rem(16px);

    @include breakpoint('sm'){
      flex: 0 0 auto;
      max-width: none;
      margin-right: rem(24px);
    }
  }

  &__link{
    text-decoration: none;

    &:focus{
      @include nsw-focus;
    }
  }
}

.section-links{
  @include font-stack('heading');

  @include breakpoint('sm'){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  a{
    @include text-link;
  }

  &__group{
    margin-bottom: rem(24px);
    @include breakpoint('sm'){
      flex: 1 0 50%;
      max-width: 50%;
    }

    @include breakpoint('md'){
      flex: 1 0 25%;
      max-width: 25%;
    }
  }

  &__heading{
    margin: 0 0 rem(4px) 0;
  }

  &__list{
    margin: 0 0 rem(8px) 0;
    padding: 0;
    list-style: none;
  }

  &__item{
    margin: 0 0 rem(8px) 0;

    a{
      font-weight: 400;
    }
  }
}

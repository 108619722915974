$infobar--bg: $nsw-primary-blue;
$infobar--text-color: $white;
$skip-to--bg: $nsw-primary-blue;
$skip-tp--text-color: $white;
$header--bg: $white;
$header-button--text-color: $nsw-primary-blue;
$search-input--text-color: $dark80;

.nsw-skipto{
  @include font-stack;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &__link{
    @include nsw-spacing(padding, xs md);
    background-color: $nsw-primary-blue;
    color: $skip-tp--text-color;
    transform: translateY(-101%);
    display: block;
    text-align: center;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    text-decoration: none;

    &:focus,
    &:active{
      transform: translateY(0);
      outline: none;

      span{
        @include nsw-focus;
      }
    }

    @include breakpoint('sm'){
      text-align: left;
    }
  }
}

.nsw-infobar{
  @include font-stack;
  @include nsw-spacing(padding, sm none);
  background-color: $infobar--bg;
  color: $infobar--text-color;

  p{
    margin: 0;
    font-size: rem(12px);
  }
}

.nsw-header{
  background-color: $header--bg;
  position: relative;

  &__container{
    @include container;
    position: relative;
  }

  &__wrapper{
    @include nsw-spacing(padding, sm none);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: rem(72px);

    @include breakpoint('sm'){
      justify-content: unset;
      height: rem(94px);
    }
  }

  &__center{
    order: 2;
    flex-grow: 2;
    display: flex;
    justify-content: center;

    @include breakpoint('sm'){
      order: 0;
      justify-content: flex-start;
    }
  }

  &__left{
    order: 1;
    margin-left: rem(-6px);
  }

  &__right{
    order: 3;
    margin-right: rem(-4px);

    @include breakpoint('sm'){
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__logo-link{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:focus{
      @include nsw-focus;
    }
    @include breakpoint('sm'){
      padding: 0;
      justify-content: flex-start;
    }
  }
}

.nsw-header__logo{
  height: rem(48px);
  width: rem(52px);
  display: block;
  font-size: 26px;
  margin-right: 20px;
  @include breakpoint('sm'){    
    height: rem(76px);
    width: rem(76px);
    margin-right: 38px;
  }
}

.nsw-header__logo-text{
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  margin-top: 5px;
  font-family: 'Public Sans', sans-serif;
  font-kerning: normal;
  flex: 0;
  @include breakpoint('sm'){
    font-size: 20px;
    line-height: 22px;
    flex: 1;
  }
  
}

.nsw-header-btn{
  color: $header-button--text-color;
  width: rem(48px);
  height: rem(48px);
  background: none;
  border: 0;
  padding: 0;
  text-align: center;
  position: relative;
  border-radius: $border-radius;

  &:hover{
    @include nsw-hover;
  }

  &:focus{
    @include nsw-focus;
  }

  &__icon{
    height: rem(16px);
    width: rem(16px);
  }

  &__sub{
    @include font-stack('heading');
    font-size: rem(12px);
  }
}

.nsw-header-btn--menu{
  @include breakpoint('sm'){
    display: none;
  }
}

.nsw-header-btn--search{
  @include breakpoint('sm'){

    .nsw-header-btn__sub{
      @include sr-only;
    }

    .nsw-header-btn__icon{
      width: rem(32px);
      height: rem(32px);
    }
  }
}

.nsw-search{

  &__area{
    @include breakOut(absolute);
    background: $light10;
    top: 0;

    @include breakpoint('sm'){
      @include breakOutReset;
      position: absolute;
      width: rem(394px);
      right: rem(16px);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__input{
    @include font-stack;
    @include font-size('md');
    background: none;
    padding: rem(8px) rem(72px) rem(8px) rem(16px);
    color: $search-input--text-color;
    width: 100%;
    appearance: none;
    border: 1px solid $dark70;
    background-color: $white;
    height: rem(72px);

    &:focus{
      // @include nsw-focus;
      outline: rem(3) solid $nsw-tertiary-blue;
      outline-offset: rem(3);
    }

    @include breakpoint('sm'){
      @include nsw-spacing(padding, none md);
      height: rem(50px);
      padding: 0 rem(16px);
      border-radius: $border-radius;
    }
  }

  &__search-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(16px);
    width: rem(24px);
    height: rem(24px);
    display: none;

    @include breakpoint('sm'){
      display: inline-block;
    }
  }

  &__close-btn{
    width: rem(48px);
    height: rem(48px);
    background: none;
    border: 0;
    padding: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(16px);

    &:hover{
      @include nsw-hover;
    }

    &:focus{
      @include nsw-focus;
    }

    @include breakpoint('sm'){
      display: none;
    }
  }

  &__close-icon{
    width: rem(24px);
    height: rem(24px);
  }
}

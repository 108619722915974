// sass-lint:disable no-color-hex

// Base font size
$base-font-size: 16px;

// primary font stack
$font-stack: 'Montserrat','Public Sans', Arial, sans-serif !default;
$font-weight: (
  regular: 400,
  heading: 600,
) !default;

//Font sizes presets (mobile, desktop)
$font-sizes: (
  xs:   (14px, 14px),
  sm:   (16px, 16px),
  md:   (18px, 20px),
  lg:   (20px, 24px),
  xl:   (24px, 28px),
  xxl:  (26px, 32px),
  xxxl: (32px, 56px)
) !default;

$font-size-reponsive-breakpoint: 'md';

// Lineheight mapping
$line-height: (
  nospace:  1,
  default:  1.25,
) !default;

$transitions: (
  base: all 0.25s ease-in-out,
) !default;

$border-radius: 4px !default;
$border-width: 1px !default;

// Spacing - Used with margin and padding
$spacing-sizes: (
  none:   0,    //0rem
  xs:     8px,  //0.5rem
  sm:     12px, //0.8rem
  md:     16px, //1rem
  lg:     24px, //1.5rem
  xl:     32px, //2rem
  xxl:    48px, //3rem
  xxxl:   64px, //4rem
  xxxxl:  80px  //5rem
) !default;

// Predefined Colours and their names
// Brand
$nsw-primary-blue:      #002664 !default;
$nsw-primary-highlight: #d7153a !default;
$nsw-highlight-blue:    #2e5299 !default;
$nsw-tertiary-blue:     #00abe6 !default;

// Shades
$black:   #000000 !default;
$dark80:  #333333 !default;
$dark70: 	#4c4f55 !default;
$dark60: 	#6d7079 !default;
$light40: #a0a5ae !default;
$light20: #e4e4e6 !default;
$light10: #f4f4f7 !default;
$light10search: rgba(255,255,255,0.85) !default;
$white:   #ffffff !default;


// Notifications
$info-blue:      #2e5299 !default;
$info-blue-light10: #eaedf4 !default;
$success-green:  #00a908 !default;
$success-green-light10: #e5f6e6 !default;
$warning-orange: #dc5800 !default;
$warning-orange-light10: #fbeee5 !default;
$error-red:      #b81237 !default;
$error-red-light10: #f7e7eb !default;

// sitewide message
$default-blue10: #e6e9ef;
$alert-yellow: #ffcb2d;

// Notification variations
$notification: (
  info: (
    border: $info-blue,
    bg: $info-blue-light10,
    fill: $info-blue,
  ),
  success: (
    border: $success-green,
    bg: $success-green-light10,
    fill: $success-green,
  ),
  warning: (
    border: $warning-orange,
    bg: $warning-orange-light10,
    fill: $warning-orange,
  ),
  error: (
    border: $error-red,
    bg: $error-red-light10,
    fill: $error-red,
  ),
) !default;


//Box Shadows
$shadows: (
  menu: 0 4px 9px -5px rgba($black, 0.4),
  card: 0 4px 12px 0 rgba($black, 0.15),
  card-hover: 2px 6px 12px 0 rgba($black, 0.25),
) !default;

//Focus and Hover
$focus-color: $nsw-tertiary-blue !default;
$focuses: (
  default: 0 0 0 3px $focus-color,
  inset: inset 0 0 0 3px $focus-color,
  card: (0 0 0 3px $focus-color, map-get($shadows, card-hover))
) !default;

$hover-colour: rgba($nsw-tertiary-blue, 0.2);

//Links
$link-color: $nsw-primary-blue;
$link-hover--outline-color: rgba($nsw-tertiary-blue, 0.2);

$overlay-white: rgba($white, 0.8);
$overlay-black: rgba($black, 0.8);
$error-message: rgba($error-red, 0.1);

// Breakpoints
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
) !default;

$icon-colors: (
  error: $error-red,
  success: $success-green,
  info: $info-blue,
  warning: $warning-orange,
  default: $nsw-primary-blue,
) !default;

// Container
$container-offset: 16px;
$container-max-width: 1200px;

// Grid
$grid-gutters: 8px;
$grid-container-offset: -($grid-gutters);
$grid-margins: (
  xs: 8px,
  sm: 8px,
  md: 8px,
  lg: 32px,
);
$grid-margin-top: 16px;

$grid-col-sizes: (
  xs: (
    half: 50%,
  ),
  sm: (
    third: 33.3%,
    two-thirds: 66.6%,
    quarter: 50%,
    three-quarters: 50%
  ),
  md: (
    quarter: 25%,
    three-quarters: 75%
  )
);

$button-variations: (
  primary: (
    color: $white,
    colorHover: $white,
    bg: $nsw-primary-blue,
    bgHover: lighten($nsw-primary-blue, 5.88),
    border: $nsw-primary-blue,
    borderHover: lighten($nsw-primary-blue, 5.88)
  ),
  highlight: (
    color: $white,
    colorHover: $white,
    bg: $nsw-primary-highlight,
    bgHover: lighten($nsw-primary-highlight, 5.88),
    border: $nsw-primary-highlight,
    borderHover: lighten($nsw-primary-highlight, 5.88)
  ),
  outline: (
    color: $dark80,
    colorHover: $white,
    bg: $white,
    bgHover: $nsw-primary-blue,
    border: $nsw-primary-blue,
    borderHover: $nsw-primary-blue
  ),
  white: ( // sass-lint:disable-line no-color-keywords
    color: $dark80,
    colorHover: $dark80,
    bg: $white,
    bgHover: $light20,
    border: $white,
    borderHover: $light20
  ),
)  !default;

$sitewide-message: (
  default: (
    color: $dark80,
    bg: $default-blue10,
  ),
  dark: (
    color: $white,
    bg: $dark80,
  ),
  alert: (
    color: $dark80,
    bg: $alert-yellow,
  ),
)  !default;

.dpie-news{
    .nsw-grid>.nswcol{
        padding: 0 rem(16);
    }

    .news-search_summary{
        font-size: rem(13);
    }

    .nsw-col{
        flex: 0 0 100%;
        max-width: 100%;
    }

    p{
        line-height: 1.4;
    }

    small{
        font-size: 80%;
    }

    .nsw-container{
        padding-left: 0;
        padding-right: 0;
        max-width: rem(1200);
        margin-left: auto;
        margin-right: auto;
    }

    .form__item{
        margin-bottom: rem(16);
    }

    .search-filter{
        border: 0;
        border-bottom: rem(1) solid $light20;
        margin: 0;
        padding: rem(8);
    }

    .nsw-form-input{
        margin: rem(4) 0;
        box-sizing: border-box;
        font-weight: 400;
        font-size: rem(16);
        line-height: 1.25;
        padding: rem(12) rem(16);
        border-radius: rem(4);
        background-color: $white;
        border: rem(1) solid $dark70;
        vertical-align: middle;
        appearance: none;
        width: 100%;
    }

    .nsw-form-fieldset {
      position: relative;

      & legend {
        display: block;
        float: left;
      }
    }

    legend.sr-only {
      border: 0;
      clip: rect(rem(1) rem(1) rem(1) rem(1));
      clip-path: inset(50%);
      height: rem(1);
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: rem(1);
      white-space: nowrap;
    }

    h2{
        font-size: rem(28);
        margin: 0;
    }

    // .search-filter__toggle{
    button.filter__button{
        background: none;
        border: 0;
        padding: rem(8) rem(48) rem(8) 0;
        line-height: rem(16);
        position: relative;
        display: block;
        font-size: rem(14);
        font-weight: 700;
        color: $black;
        appearance: none;
        width: 100%;
        text-align: left;
        cursor: pointer;
    }

    .search-filter__icon{
        transform: rotate(90deg);
        width: rem(16);
        height: rem(16);
        position: absolute;
        right: rem(16);
        top: rem(8);
    }

    .search-filter__filters[aria-hidden=true]{
        display: none;
    }

    .search-filter__filters{
        margin-top: rem(16);
        margin-bottom: rem(8);
    }

    .search-grid__filters {
      @include breakpoint(sm) {
        flex-basis: 25%;
        max-width: 25%;
      }
    }

    .form__item{
        margin-bottom: rem(16);
    }

    .nsw-form-select,
    .nsw-form-input{
        font-family: $font-stack;
        box-sizing: border-box;
        margin-bottom: rem(4);
        font-size: rem(16);
        font-weight: 400;
        line-height: 1.25;
        padding: rem(12) rem(16);
        border-radius: rem(4);
        background-color: $white;
        border: rem(1) solid $dark70;
        vertical-align: middle;
        width: 100%;
        appearance: none;
    }

    .nsw-form-select{
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23002664' xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M16 26l16-16-4-4-12 12L4 6l-4 4 16 16z'/%3E%3C/svg%3E");
        padding-right: rem(48);
        background-repeat: no-repeat;
        background-position: right rem(16) center;
        background-size: rem(16) auto;
    }

    .nsw-form-checkbox{
        margin: 0;
        clear: left;

        &__input{
            position: absolute;
            opacity: 0;
        }
    }

    .search-filter__checkbox{
        margin-top: rem(10);

        .nsw-form-checkbox__label{
            line-height: rem(20);
            padding-left: rem(30);
            position: relative;
            padding-top: 0;
            font-weight: 400;
            font-size: rem(16);
            font-family: $font-stack;
            min-height: rem(32);

            &::before{
                position: absolute;
                width: rem(20);
                height: rem(20);
                top: 0;
                margin: 0;
                border-radius: rem(4);
                content: '';
                border: rem(1) solid $dark70;
                background: $white;
                left: 0;
                display: inline-block;
                vertical-align: middle;
                background-color: $white;
            }
        }
    }

    .nsw-form-checkbox__input:checked ~ .nsw-form-checkbox__label::before{
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M29.362 3.742L10.495 22.946l-7.857-7.997L0 17.634l9.175 9.34 1.319 1.284 1.318-1.284L31.999 6.427z'/%3E%3C/svg%3E");
        background-color: $nsw-primary-blue;
        background-size: contain;
    }

    .form__item{
        margin-bottom: rem(16);
    }

    .form-checkboxes{
        label{
            display: block;
        }
    }

    .search-autocomplete__items{
        width: 80%;

        @include breakpoint(sm){
            max-width: rem(270);
        }
    }

}
.nsw-card {
  margin-left: auto;
  margin-right: auto;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__icon {
    margin: auto 0 0;
  }

  &__image-area {
    background-position: center center;
    background-size: cover;
  }

  .nsw-card__title a {
    font-size: rem(20);
    line-height: rem(24);
    font-weight: 600;
    color: $nsw-primary-blue;
  }

  .nsw-card__title span {
    font-size: rem(20);
    line-height: rem(24);
    font-weight: 600;
    color: $nsw-primary-blue;
  }
}

.nsw-card--news {

  .nsw-card__image-area {
    order: 1;
    height: rem(200px);
  }

  .nsw-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .nsw-card__content {
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .nsw-card__tag {
    order: 2
  }

  .nsw-card__date {
    order: 3
  }

  .nsw-card__title,
  .nsw-card__copy,
  .nsw-card__icon {
    order: 4
  }
}

.nsw-card--events {

  .nsw-card__image-area {
    order: 1;
    height: rem(200px)
  }

  .nsw-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .nsw-card__content {
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .nsw-card__tag {
    order: 2
  }

  .nsw-card__details {
    order: 3
  }

  .nsw-card__title,
  .nsw-card__copy,
  .nsw-card__icon {
    order: 4
  }
}

.nsw-card__details {
  @extend .nsw-card__date;
}

.nsw-cards {

  &__title {

    &.primary-blue{
      color: $nsw-primary-blue
    }
    
    color: $dark80;
    font-family: $font-stack;
    font-size: 1.625rem;
    line-height: 1.25;
    margin: auto 0 0;

    @include breakpoint(md) {
      font-size: 2rem;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 1rem;
    max-width: 25rem;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }

  &__cta {
    color: $nsw-primary-blue;
    font-family: $font-stack;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25;
    margin: auto 0 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $focus-color;
    }
  }

  .nsw-section {
    padding: 3.125rem 0 4.5rem;
  }

  .nsw-col--third, 
  .nsw-col--quarter {
    height: auto;
    margin-top: 1rem;
  }

  .nsw-grid {
    margin-top: -1rem;
  }
}
.nsw-table{
  @include font-stack;
  @include font-size('sm');
  border-collapse: collapse;
  border-radius: 0;
  width: 100%;
  margin: 0 auto;
  caption-side: top;

  caption {
    @include nsw-spacing(padding, sm xs);
    text-align: left;
    padding-bottom: 24px;
  }

  th,
  td{
    @include nsw-spacing(padding, sm xs);
    text-align: left;
    vertical-align: baseline;
    border-bottom: solid 1px $dark80;
  }

  th,
  thead td,
  tfoot td {
    @include font-stack('heading')
  }

  thead{
    border-bottom: solid 2px $dark80;
  }
}

.nsw-table--caption-top{
  caption-side: top;
}

.nsw-table--striped {
  th,
  td{
    border-bottom: solid 1px $light20;
  }

  tbody tr:nth-of-type(even) {
    background-color: $light10;
  }
}


.nsw-table--bordered {
  th,
  td{
    border: solid 1px $dark70;
  }
}

.nsw-table-responsive{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: rem(50) 0;

  &:focus{
    @include nsw-focus();
  }
}

.nsw-table--stacked{
  margin: rem(50) 0;

  .nsw-table__heading{
    display: none;
  }

  @include breakpoint('sm', max-width){
    border-top: solid 2px $dark80;

    thead{
      @include sr-only;
    }

    tbody tr{
      display: block;
      border-bottom: solid 1px $dark80;
    }

    th,
    td{
      display: block;
      border: 0;
      border-bottom: solid 1px $light40;
    }

    .nsw-table__heading{
      display: block;
    }
  }
}

.nsw-page-nav{

    &:first-child{
        margin-top: 0;
        margin-bottom: rem(16);
    }

    border-left: rem(4) solid $nsw-primary-highlight;
    padding: rem(16);

    &__title{
        font-size: rem(18);
        margin: 0;
        font-weight: 600;
        line-height: 1.33;
    }

    &__list{
        margin: 0;
        padding: 0;
        list-style: none;

        &-item{
            margin-top: rem(16);
            display: block;
        }
    }

    &__link a{
        font-weight: 600;
        color: $nsw-primary-blue;
        display: inline-block;
    }
}
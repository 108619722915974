.dpie-news{
    .nsw-grid{
        margin-left: rem(-16);
        margin-right: rem(-16);
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
    }
}

.nsw-block{
  max-width: rem(800px);
  margin: 0 auto;

  &__wrapper {
    @include breakpoint('sm'){
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__title {
    margin-bottom: rem(16px);
    @include breakpoint(sm) {
      margin-bottom: rem(9px);
    }
  }

  &__image {
    @include breakpoint('sm'){
      width: rem(220px);
      min-width: rem(220px);
      padding-left: rem(20px);
      padding-bottom: 0;
    }

    @include breakpoint('md'){
      width: rem(270px);
      min-width: rem(270px);
      padding-left: rem(30px);
    }

    img {
      display: block;
      width: 100%;
      border: 0;
  	}
  }

  &__link {
    .nsw-button {
      margin: 0;
    }
  }
}

.nsw-hero-banner{
  $parent: &;
  position: relative;


  &>.nsw-container__hero-search {
    padding-left: 0;
    padding-right: 0;
    max-width: initial;
  }

  @include breakpoint('sm'){
    overflow: hidden;
  }

  &__image{
    @include breakOut;
    height: auto;
    display: block;

    @include breakpoint('sm'){
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-bottom: 0;
      z-index: -3;
    }

    &-area{
      width: 100%;
      height: auto;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__content-area{
    @include breakOut;

    @include breakpoint('sm'){
      @include breakOutReset;
      height: rem(380);
      background-color: transparent;
      width: 100%;
    }
  }

  &__content{
    @include font-stack();
    @include font-size('md');
    @include nsw-spacing(padding, md);
    background-color: $light20;
    border-top: solid 6px $nsw-primary-highlight;

    @include breakpoint('sm'){
      @include nsw-spacing(padding, md lg);
      position: relative;
      top: 50%;
      background-color: $overlay-white;
      transform: translateY(-50%);
      max-width: rem(600px);
    }

    p{
      margin: 0;
    }

    * + p{
      @include nsw-spacing(margin-top, md);
    }

    &:focus-within{
      @include nsw-focus;

      .nsw-hero-banner__link{
        text-decoration: none;
      }
    }
  }

  &__title{
    @include font-stack('heading');
    @include font-size('xxl');
    @include nsw-spacing(margin, none);
  }

  &__icon{
    @include nsw-spacing(margin, md none none none);
  }

  &__link{
    text-decoration: none;
    color: $dark80;

    &:hover{
      text-decoration: underline;
    }

    &:focus{
      outline: none;
      text-decoration: underline;
    }

    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &--dark{

    #{ $parent }__content {
      color: $white;
      background-color: $dark80;

      @include breakpoint('sm'){
        background-color: $overlay-black;
      }
    }

    #{ $parent }__link {
      color: $white;
    }

    #{ $parent }__icon {
      fill: $white;
    }
  }

  .search-autocomplete__items{
      top: rem(50);
    
      @include breakpoint('md'){
        top: rem(46);
      }

      .search-autocomplete__item{
        text-align: left;
      }
  }
}
html{
  line-height: 1.25;
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body{
  margin: 0;
}

ol {
  list-style-type: decimal;  

  & li ol {
    list-style-type: lower-alpha;

    & li ol {
      list-style-type: lower-roman;
    }
  }
}

.list-decimal {
  list-style-type: decimal;
}

.lower-alpha {
  list-style-type: lower-alpha;
}

.lower-roman {
  list-style-type: lower-roman;
}
// sass-lint:disable no-important
@function rem($value) {
  @if (unitless($value)) {
    $value: $value * 1px;
  }

  @return $value / $base-font-size * 1rem;
}

@function em($value) {
  @if (unitless($value)) {
    $value: $value * 1px;
  }

  @return $value / $base-font-size * 1em;
}

// generates modifer classes in the bem syntax
@mixin helper-classes($attribute, $map) {

  @each $modifier, $value in $map {
    &--#{$modifier} {
      #{$attribute}: get-mapped-value($modifier, $map) !important;
    }
  }
}

//gets mapped px values and returns it as rem
@function get-mapped-value($key, $map){
  @each $mapkey, $mapvalue in $map {
    @if $mapkey == $key{
      @return rem($mapvalue);
    }
  }
}

// break out of the nsw-container class
@mixin breakOut($position: relative) {
  width: 100vw;
  position: $position;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@mixin breakOutReset {
  width: auto;
  position: static;
  margin-left: auto;
  margin-right: auto;
  left: auto;
  right: auto;
}

// sass-lint:disable no-color-literals

.video-wrapper {
  box-shadow: 0 rem(4px) rem(12px) 0 rgba($black, 0.15);
  margin: 0 0 1em 0;
}

.video-description {
  color: $caption-color;
  font-family: $font-stack;
  line-height: 1.5;
  padding: rem(24px) rem(32px);
  position: relative;

  &::before {
    content: '';
    height: rem(6px);
    width: 100%;
    position: absolute;
    top: rem(-6px);
    left: 0;
    background-color: $nsw-primary-highlight;
  }
}

.video-frame-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 0;
}

.video-wrapper iframe,
.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nsw-socials{
  @include nsw-spacing(margin, sm none none none);
  list-style: none;
  padding: 0;
  display: flex;

  &__item{
    margin-right: rem(16px);
  }

  &__link{
    text-decoration: none;
    display: inline-block;
    width: rem(24px);
    height: rem(24px);

    &:focus{
      @include nsw-focus;
    }
  }

  &__icon{
    width: rem(24px);
    height: rem(24px);
  }
}

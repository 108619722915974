.nsw-tag {
  @include nsw-spacing(margin, lg none );
  @include font-stack('body');
  @include nsw-spacing(margin, md xs xs none);
  @include nsw-spacing(padding, xs sm );
  @include font-size('sm');
  border: 2px solid $light40;
  border-radius: $border-radius;
  background-color: $white;
  display: inline-block;
  text-decoration: none;
  cursor: default;
  color: $dark80;

  &:hover {
    @include nsw-hover;
  }

  &:focus {
    @include nsw-focus;
  }

  &.is-selected{
    background-color: $nsw-highlight-blue;
    border-color: $nsw-highlight-blue;
    color: $white;
  }
}

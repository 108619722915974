.nsw-container{
  @include container;

  &--flush{
    padding-left: 0;
    padding-right: 0;
  }
}

.nsw-grid{
  display: flex;
  flex-wrap: wrap;
  margin-left: rem($grid-container-offset);
  margin-right: rem($grid-container-offset);
  align-items: stretch;

  & > .nsw-col{
    padding-left: rem($grid-gutters);
    padding-right: rem($grid-gutters);
  }

  &--no-gutters {
    margin-left: 0;
    margin-right: 0;

    & > .nsw-col{
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.nsw-row::after{
  content: '';
  clear: both;
  display: table;
}

@include grid-columns()

.nsw-icon{
  display: inline-block;
  width: 2em;
  height: 2em;
  fill: map-get($icon-colors, default);

  &--error{
    fill: map-get($icon-colors, error);
  }

  &--success{
    fill: map-get($icon-colors, success);
  }

  &--info{
    fill: map-get($icon-colors, info);
  }

  &--warning{
    fill: map-get($icon-colors, warning);
  }

  &--rotate-90{
    transform: rotate(90deg);
  }

  &--rotate-180{
    transform: rotate(180deg);
  }

  &--rotate-270{
    transform: rotate(270deg);
  }
}

.nsw-button{
  @include font-stack('heading');
  @include font-size('sm');
  @include nsw-spacing(margin, md md none none);
  border: solid 2px transparent;
  border-radius: rem($border-radius);
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: rem(12.5px) rem(24px);
  cursor: pointer;
  min-width: rem(124px);
  -webkit-appearance: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  display: inline-block;

  &:focus{
    @include nsw-focus;
  }

  @each $color, $value in $button-variations {
    &--#{$color} {
      @include button-variant($value);
    }
  }

  &:disabled,
  &.disabled{
    background-color: $dark70;
    color: $light20;
    border-color: $dark70;
    cursor: not-allowed;
  }
}

.nsw-button--block{
  display: block;
  width: 100%;

  & + .nsw-button--block{
    margin-bottom: 0;
  }
}

.sq-form-submit {
  @include button-variant(map-get($button-variations, primary));
  @include font-stack('heading');
  @include font-size('sm');
  // @include nsw-spacing(margin, md md none none);
  margin-bottom: 30px;
  border: solid 2px transparent;
  border-radius: rem($border-radius);
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: rem(12.5px) rem(24px);
  cursor: pointer;
  min-width: rem(124px);
  -webkit-appearance: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  display: inline-block;

  &:focus{
    @include nsw-focus;
  }
}
// sass-lint:disable no-color-keywords

$colours: (
  grey: $light10,
) !default;

.nsw-section {
  padding: rem(40px) 0;

  @include breakpoint('sm') {
    padding: rem(30px) 0;
  }

  @include breakpoint('md') {
    padding: rem(50px) 0;
  }

  &--top {
    padding-top: 0;
  }

  &--bottom {
    padding-bottom: 0;
  }

  &--video {
    padding: rem(16px) 0 rem(1px) 0;
  }

  @each $key, $value in $colours {
    &--#{$key} {
      background-color: $value;
    }
  }

}

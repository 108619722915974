.icon-card{
    color: $nsw-primary-blue;

    @include breakpoint(sm) {
      display: flex;
      margin-left: rem(-10);
    }

    .nsw-card__title{
        font-size: rem(20);
        line-height: rem(24);
        padding-left: rem(40);
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .nsw-card{

        margin-left: initial;
        margin-right: initial;
        max-width: initial;

        &__link {
          color: currentColor;
        }

        @include breakpoint(sm){
            flex: 1;
            height: initial;
            margin-left: rem(10);
        }
    }

    .nsw-card__icon-top{
        margin-bottom: rem(14);
        
        @include breakpoint(sm){
            margin-top: 0;
        }
    }

    .nsw-card__content{
        @include breakpoint(sm){
            padding: rem(16);
        }
    }
    
}
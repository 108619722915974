.nsw-card--dpie-small{
    .nsw-card{
        max-height: initial;
    }
}

.nsw-card--dpie{
 
    @include breakpoint(sm){
        margin-left: rem(-22);
    }

    .nsw-card{
        display: flex;
        flex-direction: column;
        max-width: initial;
        
        @include breakpoint(sm){
            height: initial;
            width: 100%;
            margin-left: rem(22);
            margin-right: 0;
            flex-direction: row;
        }
    }

    .nsw-card__title{
        a{
            color: $nsw-primary-blue;
            font-size: rem(20);
            line-height: rem(24);
            font-weight: 600;
        }
    }

    .nsw-card__copy{
        font-size: rem(16);
        line-height: rem(20);
    }

    .nsw-card__content,
    .nsw-card__image-area{
        flex: 1 1 rem(0);
    }

    .nsw-card__content{
        border-top: 0;
        padding: 0;
        height: initial;

        &>div{
            padding: rem(16);
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .nsw-card--media{

        .nsw-card__image-area{
            height: rem(240);
        }

        @include breakpoint(sm){
            align-items: stretch;

            .nsw-card__image-area{
                min-height: 100%;
            }
        }        
    }

    @include breakpoint(sm){
        display: flex;
    }
}
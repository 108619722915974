.nsw-search-listing {
  &__item {
    margin-bottom: rem(10px);
    border-left: rem(6px) solid $nsw-primary-blue;
    
    margin: rem(25px) rem(-16px) rem(35px) rem(-16px);
    
    @include breakpoint('md') {
      margin-bottom: rem(60px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__link {
    text-decoration: none;

    &:hover, 
    &:focus {
      .nsw-search-listing__title {
        text-decoration: underline;
      }
    }

    &:focus {
      .nsw-search-listing__title {
        @include nsw-focus;
      }
    }

  }

  &__content {
    width: 100%;
  }

  &__header,
  &__summary {
    padding: rem(16px);
    @include breakpoint('sm') {
      padding: rem(27px);
    }
  }

  &__header {
    text-decoration: none;
    background: $light10;
  }

  &__title {
    @include font-stack('heading');
    @include font-size('md');
    color: $dark80;
    margin: 0;
  }

  &__meta {
    border-top: rem(1px) solid $light20;
    padding-top: rem(18px);
    margin-top: rem(20px);
    font-size: rem(14px);

    &-separator {
      color: $dark80;
    }
  }

  &__publish-date {
    @include font-stack('body');
    color: $dark80;
  }

  &__summary {
    @include font-stack('body');
    @include font-size('sm');
    line-height: rem(20px);
    color: $dark70;

    p:first-of-type {
      margin-top: 0;
    }

    .nsw-tag {
      pointer-events: none;
    }
  }
}
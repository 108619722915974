.nsw-breadcrumb {
  display: none;
  margin: 0;
  padding: rem(50px) 0;

  @include breakpoint(sm) {
    display: block;
  }

  &__gap{

    @include breakpoint(sm){
      padding-top: rem(50);
    }
  }
}

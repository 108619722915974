.nsw-search-listing {
  @include font-stack('body');

  &__intro-text{
    margin-bottom: rem(25px);
    color: $dark80;
  }

  &__info {
    margin-top: rem(30);
    font-size: rem(14px);
    text-transform: uppercase;
  }

  &__pagination {
    ul.nsw-pagination {
      justify-content: center;
    }
  }
}

.nsw-grid--grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;

  @include breakpoint(md) {
    grid-template-columns: 1fr rem(60px) rem(300px);
    grid-template-rows: auto auto auto;
  }

  .nsw-search-box {
    grid-row: 1;
    grid-column: 1;

    @include breakpoint(md) {
      grid-row: 1;
      grid-column: 1;
    }
  }

  .nsw-search-tags {
    grid-row: 2;
    grid-column: 1;

    @include breakpoint(md) {
      grid-row: 2;
      grid-column: 1;
    }
  }

  .nsw-search-filters {
    grid-column: 1;
    grid-row: 3;
    margin-top: rem(20px);

    @include breakpoint(md) {
      grid-row: 1 / span 4;
      grid-column: 3;
      margin-top: 0;
    }
  }

  .nsw-search-listing__items {
    grid-column: 1;
    grid-row: 4;

    @include breakpoint(md) {
      grid-row: 3;
      grid-column: 1;
    }
  }

}

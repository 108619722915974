.nsw-accordion{
  @include nsw-spacing(margin, md none none);

  &__title{
    @include font-stack('heading');
    @include font-size('sm');
    @include nsw-spacing(margin, xs none none);
    background-color: $light10;
    border-left: solid 4px $nsw-primary-blue;
    padding: 1rem;

    .is-ready & {
      padding: 0;
      border-left: 0;
      margin-top: 0;
    }
  }

  &__button{
    @include font-stack('heading');
    @include font-size('sm');
    @include nsw-spacing(margin, xs none none);
    background-color: $light10;
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: left;
    border: 0;
    border-left: solid 4px $nsw-primary-blue;
    position: relative;

    &:hover{
      @include nsw-hover;
    }

    &:focus{
      @include nsw-focus;
      z-index: 1;
      position: relative;
    }
  }

  &__icon{
    transform: translateY(-50%) rotate(90deg);
    height: rem(16px);
    width: rem(16px);
    position: absolute;
    top: 50%;
    right: 1rem;

    .is-open &{
      transform: translateY(-50%) rotate(270deg);
    }
  }

  &__content{
    padding: 1rem;
    border-left: solid 4px $nsw-primary-blue;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

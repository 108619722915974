.nsw-side-nav {
  color: $dark80;
  font-family: $font-stack;
  margin: 0 auto;
  max-width: rem(300px);
  margin-bottom: rem(40);
  @include breakpoint(sm) {
    margin: 0 0 rem(40);
  }
}

.nsw-side-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.nsw-side-nav__list__item {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  position: relative;

  &::before {
    background-color: $nsw-primary-blue;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    width: rem(2px);
  }
}

.nsw-side-nav__list__item.active {

  > .nsw-side-nav__list__toggle {
    .nsw-icon {
      scale: -1;
    }
  }

  &::before {
    opacity: 1;
  }

  > .nsw-side-nav__list--inner {
    max-height: rem(800px);
  }
}

.nsw-side-nav__list__item.current {
  > .nsw-side-nav__list__item__link {
    background-color: $light10;
    font-weight: bold;
    grid-column: 1;
  }

  > .nsw-side-nav__list__toggle {
    background-color: $light10;
  }

  &::before {
    background-color: $nsw-primary-highlight;
    content: '';
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    width: rem(2px);
  }
}

.nsw-side-nav__list__item__link { 
  border-bottom: rem(1px) solid $light40;
  color: inherit;
  display: block;
  grid-column: 1 / span 2;
  grid-row: 1;
  padding: rem(16px);
  text-decoration: none;

  &.has-children {
    grid-column: 1;
    grid-row: 1;
  }

  &:hover {
    @include nsw-hover;
  }

  &:focus {
    @include nsw-focus(inset);
  }
}

.nsw-side-nav__list__toggle {
  align-items: center;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-bottom: rem(1px) solid $light40;
  color: inherit;
  cursor: pointer;
  display: flex;
  font: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-font-smoothing: inherit;
  grid-column: 2;
  grid-row: 1;
  height: 100%;
  justify-content: center;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0 1em 0;
  width: auto;

  .nsw-icon {
    margin: 0;
    padding: 0;
  }

  &__icon {
    height: rem(12px);
    transform: rotate(90deg);
    width: rem(12px);
  }
}

.nsw-side-nav__list--inner {
  @extend .nsw-side-nav__list;
  grid-column: 1 / span 2;
  grid-row: 2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  .nsw-side-nav__list__item__link {
    padding-left: 2em;
  }

  .nsw-side-nav__list--inner {
    .nsw-side-nav__list__item__link {
      padding-left: 3em;

      .nsw-side-nav__list--inner {
        .nsw-side-nav__list__item__link {
          padding-left: 4em;
        }
      }
    }
  }
}

.nsw-side-nav__title {
  border-bottom: rem(2px) solid $nsw-primary-blue;
  font-weight: bold;
  padding: rem(11px) 0;
}

.nsw-side-nav__list__item:not(.has-children) {
  > .nsw-side-nav__list--inner,
  > .nsw-side-nav__list__toggle {
    display: none;
  }
}
$nsw-body--text-color: $dark80;
$nsw-body--link-active-color: $nsw-primary-blue;
$nsw-body--link-hover-color: rgba($nsw-tertiary-blue, 0.2);

.nsw-wysiwyg-content {
  @extend %clearfix;
  @include font-stack;
  @include font-size("sm");
  color: $nsw-body--text-color;

  a:not(.nsw-button):not(.nsw-tag) {
    color: $nsw-body--link-active-color;
    text-decoration: underline;
    text-decoration-skip-ink: auto;

    &:hover {
      @include nsw-hover;
      outline: 2px solid $nsw-body--link-hover-color;
    }

    &:focus {
      @include nsw-focus;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font-stack("heading");
    margin: 0;
  }

  p {
    margin: 0;

    & + p {
      margin-top: 1rem;
    }
  }

  * + p {
    @include nsw-spacing(margin-top, xs);
  }

  ul + p,
  ol + p {
    margin-top: 1rem;
  }

  ul,
  ol {
    @include nsw-spacing(margin, md none none none);
  }

  blockquote {
    @include font-size("lg");
    @include nsw-spacing(margin-top, md);
    border-left: solid 6px $nsw-primary-highlight;
    padding: rem(20px) rem(24px);

    @include breakpoint("sm") {
      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
    }
  }

  cite {
    @include font-size("sm");
    @include nsw-spacing(margin-top, md);
    display: block;
    font-style: normal;
  }

  cite::before {
    content: "— ";
  }
}

// Heading Styles
.nsw-wysiwyg-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font-stack("heading");
    margin: 0;
  }

  h1 {
    @include font-size("xxxl");
  }

  h2 {
    @include font-size("xxl");
  }

  h3 {
    @include font-size("xl");
  }

  h4 {
    @include font-size("lg");
  }

  h5,
  h6 {
    @include font-size("md");
  }

  * + h2,
  * + h3 {
    @include nsw-spacing(margin-top, xxl);
  }

  * + h4 {
    @include nsw-spacing(margin-top, xl);
  }

  * + h5 {
    @include nsw-spacing(margin-top, lg);
  }

  * + h6 {
    @include nsw-spacing(margin-top, md);
  }
}

.nsw-text-link {
  @include text-link;
}

.nsw-wysiwyg-content ul,
.nsw-wysiwyg-content ol,
.nsw-wysiwyg-content ul li,
.nsw-wysiwyg-content ol li {
  margin: 1rem 0 0;
}

.nsw-wysiwyg-content .nsw-img__wrapper--left ~ ul,
.nsw-wysiwyg-content .nsw-img__wrapper--left ~ ol {
  list-style-position: inside;
  padding-left: rem(20px);
  overflow: hidden;
  margin: 0;
}

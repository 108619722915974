.nsw-callout{
  &__content{
    p:first-of-type {
      @include nsw-spacing(margin, none none none none)
    }
  }

  &__title {
    @include nsw-spacing(margin, none none sm none)
  }
}

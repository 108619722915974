.nsw-body-content {
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.5;
  color: $dark80;

  p {
    margin: 0;
  }

  *+p {
    margin-top: rem(16);
  }

  ul {
    margin: 0;

    li{
        margin-top: rem(8);
    }
  }
}

.hero-search{

    padding: rem(7);

    &__form {
       margin: rem(10) 0 rem(20);

       @include breakpoint('sm'){
           margin-top: rem(25);
       }
    }

    &__title {
      font-size: rem(22);
      font-weight: 600;
      line-height: 1.25;
      margin: 0;
      color: $nsw-primary-blue;
      padding: 0 rem(40);

      @include breakpoint('sm') {
        font-size: rem(32);
        line-height: 1.25;
      }
    }

    &__content{
        width: 100%;
        position: relative;  
        padding: rem(12) rem(6);
        border-radius: rem(15);      
        background-color: $light10search;
        text-align: center;

        &>*{
            max-width: rem(700);
            margin-left: auto;
            margin-right: auto;

            @include breakpoint('sm'){
                max-width: initial;
            }
        }

        p{
            font-size: rem(18);
            line-height: 1.33;
            margin-right: auto;
            margin-left: auto;
        }

        @include breakpoint('sm') {
            padding: rem(27) rem(97);
            width: 80%;
            margin: rem(105) auto;
            max-width: rem(1300);
        }

    }

    &__suggested{
        ul{
            padding-left: 0;
            list-style: none;
            text-align: center;
            margin-top: rem(-8);
            font-weight: 500;
            width: 85%;
            margin-left: auto;
            margin-right: auto;
        }

        li{
            margin: 0 auto;
            display: inline-block;
            padding: 0 rem(18);

            &:hover{
                background-color: $dark80;
            }

            &:hover a{
                color: $white;
            }
        }

        a{
            color: $dark70;
        }
    }

    &_image{
        height: auto;
        width: 100%;
        background-position: center;
        background-size: cover;
    }

   

    .nsw-form-search__input{
        padding-top: rem(20);
        padding-bottom: rem(20);
    }

    .nsw-container{
        padding: 0;
    }

}

.nsw-form-search{
    display: flex;
    position: relative;

    &__input{
        font-weight: 400;
        font-size: rem(16);
        line-height: 1.5;
        padding: rem(12) rem(16);
        background-color: $white;
        color: $dark80;
        border: rem(1) solid $dark70;
        border-radius: rem(15);
        vertical-align: middle;
        appearance: none;
        width: 100%;

        @include breakpoint('md') {
            font-size: rem(16);
            line-height: 1.5;
        }
    }

    &__submit{
        border-radius: rem(15);
        padding: 0 rem(18);
        border: rem(1) solid transparent;    
        display: flex;
        align-items: center;
        position: absolute;
        right: 1px;
        top: 1px;
        height: 96%;

        &:hover{
            cursor: pointer;
        }
    }
}
.nsw-navigation {
  z-index: 99;

  &__subbtn {
    height: auto;
    min-width: rem(52px);
  }
}

.nsw-navigation__active,
.js-nsw-navigation-active {
  overflow: hidden;

  @include breakpoint('sm') {
    overflow: auto;
  }
}

.nsw-navigation__list,
.nsw-subnavigation__list {
  overflow-y: auto;
  max-height: 70vh;

  @include breakpoint('sm') {
    overflow-y: visible;
    max-height: none;
  }

  &::after {
    content: '';
    display: block;
    background-color: $overlay-black;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 0;

    @include breakpoint('sm') {
      display: none;
    }
  }
}

.nsw-navigation__list-item:not(.has-children) {

  .nsw-navigation__link-icon,
  .nsw-navigation__subbtn,
  .nsw-subnavigation {
    display: none;
  }
}

.nsw-subnavigation__list {
  @include breakpoint('sm') {
    @include nsw-spacing(margin-top, xl);
    justify-content: flex-start;
  }
}

.nsw-subnavigation__list-item {
  margin-right: 2%;
  z-index: 1;

  @include breakpoint('sm') {
    &:nth-child(3n) {
      margin: 0;
    }
  }
}

.nsw-navigation__link,
.nsw-subnavigation__link {
  align-items: center;
  position: relative;
}

.nsw-navigation__link.current::before,
.nsw-subnavigation__link.current::before {
  background-color: $nsw-primary-highlight;
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 6px;

  @include breakpoint('sm') {
    height: 6px;
    width: 100%;
  }
}

.nsw-navigation__link-icon {
  position: relative;
  top: auto;
  transform: rotate(90deg);

  .is-open:not(.nsw-navigation) &,
  .is-open.nsw-navigation .is-open & {
    transform: rotate(270deg);
    // top: calc(50% - #{rem(6px)})
  }

  .is-open.nsw-navigation & {
    transform: rotate(90deg);
  }
}
